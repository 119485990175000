/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  exportIcon,
  mailCheckIcon,
  rightUserIcon,
  twoUserIcon,
} from "../assets";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Select,
  Table,
} from "antd";
import "../styles/RecruitersPage.css";
import { get, post } from "../lib/Api";
import { ToastContainer } from "react-toastify";
import Toast from "../components/Toast/Toast";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
const baseUrl = process.env.REACT_APP_BACKEND_URL;

dayjs.extend(customParseFormat);

const CandidateListPage = () => {
  const dateFormat = "DD/MM/YYYY";
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const zones = [
    { value: "North", label: "North" },
    { value: "East", label: "East" },
    { value: "South", label: "South" },
    { value: "West", label: "West" },
  ];
  const [searchValue, setSearchValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchValue);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedCandidateType, setSelectedCandidateType] = useState("all");
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [selectedCandidateIds, setSelectedCandidateIds] = useState([]);
  useState(false);

  const [candidateCardCount, setCandidateCardCount] = useState({
    totalCandidates: "",
    selfUpdate: "",
    updatedByRecruiter: "",
    invitedCandidates: "",
  });

  const [recruiterExportData, setRecruiterExportData] = useState({
    state: "",
    zone: "",
    startDate: null,
    endDate: null,
  });

  const [candidateType, setCandidateType] = useState([]);

  const handleExportStateChange = (value, option) => {
    setRecruiterExportData((prevData) => ({ ...prevData, [option.id]: value }));
  };

  const handleExportDateChange = (date, id) => {
    setRecruiterExportData({
      ...recruiterExportData,
      [id]: date,
    });
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      pageSizeOptions: ["10", "20", "50", "100"],
      showSizeChanger: true,
    },
  });

  const columns = [
    {
      title: (
        <Checkbox
          onChange={(e) => handleSelectAll(e.target.checked)}
          checked={
            selectedCandidateIds?.length === data?.length && data?.length > 0
          }
          // indeterminate={
          //   selectedCandidateIds.length > 0 &&
          //   selectedCandidateIds.length < data.length
          // }
        />
      ),
      dataIndex: "id",
      key: "select",
      width: "5%",
      render: (id) => (
        <Checkbox
          onChange={(e) => handleSelect(id, e.target.checked)}
          checked={selectedCandidateIds.includes(id)}
        />
      ),
    },
    {
      title: "Name & Email",
      sorter: true,
      key: "name",
      render: (result) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <div><img src={profileUserIcon} alt="User Icon"/></div> */}
          <div style={{ paddingLeft: "10px" }}>
            <div
              style={{ fontWeight: 500, fontSize: "14px", lineHeight: "17px" }}
            >{`${result.name}`}</div>
            <div
              style={{ fontWeight: 400, fontSize: "14px", lineHeight: "17px" }}
            >
              {result.email}
            </div>
          </div>
        </div>
      ),
      width: "25%",
    },
    {
      title: "Experience",
      dataIndex: "experience",
      width: "13%",
      align: "center",
      key: "experience",
      render: (result) => <>{result} Year</>,
    },
    {
      title: "Annual Salary",
      dataIndex: "annual_salary",
      width: "15%",
      align: "center",
      key: "annual_salary",
      render: (result) => <>₹ {result}</>,
    },
    {
      title: "Location",
      // dataIndex: "zone",
      width: "10%",
      align: "center",
      key: "location",
      render: (result) => (
        <>
          {" "}
          {result?.city}, {result?.state}
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "candidate_type",
      width: "10%",
      align: "center",
      key: "candidate_type",
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      width: "15%",
      align: "center",
      key: "last_login",
      sorter: true,
      render: (result) => (
        <>{result ? dayjs(result).format("DD-MM-YYYY") : "N/A"}</>
      ),
    },
  ];

  const getRandomuserParams = (params) => ({
    page_no: params.pagination?.current,
    page_size: params.pagination?.pageSize,
    query: debouncedValue,
    candidate_type: selectedCandidateType,
    invited: selectedStatus,
    sort_order: params.sortOrder,
  });

  const fetchData = async () => {
    setLoading(true);
    const res = await get(
      `candidate/master-list`,
      getRandomuserParams(tableParams),
      {},
      true
    );
    if (res?.status === true) {
      setData(res.candidate_profile_list.results);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.count,
        },
      });
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchCandidateCardCount = async () => {
    setLoading(true);
    const res = await get(`candidate/count`, null, {}, true);
    if (res?.status === true) {
      setCandidateCardCount({
        totalCandidates: res.data.total,
        selfUpdate: res.data.self_update,
        updatedByRecruiter: res.data.updated_by_recruiter,
        invitedCandidates: res.data.invited,
      });
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchCandidateType = async () => {
    setLoading(true);
    const res = await get(`candidate/type`, null, {}, true);
    if (res?.status === true) {
      setCandidateType(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchStates = async () => {
    setLoading(true);
    const res = await get(`master/state-list`, { country_id: 1 }, {}, true);
    if (res?.status === true) {
      setStates(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    fetchData();
    fetchCandidateCardCount();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
    debouncedValue,
    selectedStatus,
    selectedCandidateType,
  ]);

  useEffect(() => {
    fetchStates();
    fetchCandidateType();
  }, []);

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleCandidateTypeChange = (value) => {
    setSelectedCandidateType(value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
      filters,
      sortOrder: Array.isArray(sorter)
        ? undefined
        : `${sorter.columnKey}: ${sorter.order}`,
    }));

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleExportData = async () => {
    setLoading(true);
    try {
      if (!recruiterExportData.startDate) {
        Toast({ message: "Please fill all required files", type: "error" });
        setLoading(false);
        return;
      }
      let res;
      const data = {
        ...recruiterExportData,
        startDate: recruiterExportData.startDate.format(dateFormat),
        endDate: recruiterExportData.endDate
          ? recruiterExportData.endDate.format(dateFormat)
          : null,
      };
      res = await post(`user/recruiter-bulk-export`, data, {}, true);
      if (res?.status === true) {
        setRecruiterExportData({
          state: "",
          zone: "",
          startDate: null,
          endDate: null,
        });
        const element = document.createElement("a");
        element.href = baseUrl + res.file_path;
        element.download = "";
        element.click();
        setIsExportModalOpen(false);
        Toast({ message: "Report successfully downloaded", type: "success" });
      } else {
        Toast({ message: res?.message, type: "error" });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
      Toast({
        message: "Network Error",
        type: "error",
      });
    }
  };

  const handleSelect = (candidateId, isChecked) => {
    setSelectedCandidateIds((prev) =>
      isChecked
        ? [...prev, candidateId]
        : prev.filter((id) => id !== candidateId)
    );
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allIds = data.map((candidate) => candidate.id);
      setSelectedCandidateIds(allIds);
    } else {
      setSelectedCandidateIds([]);
    }
  };

  const handleInvite = async () => {
    setLoading(true);
    if (!selectedCandidateIds.length) {
      Toast({
        message: "Please select at least single candidate to invite",
        type: "error",
      });
      setLoading(false);
      return;
    }
    try {
      let res;
      const data = {
        candidates_list: selectedCandidateIds,
      };
      res = await post(`candidate/invite-message`, data, {}, true);
      if (res?.status === true) {
        setSelectedCandidateIds([]);
        fetchData();
        Toast({ message: res.message, type: "success" });
      } else {
        Toast({ message: res?.message, type: "error" });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
      Toast({
        message: "Network Error",
        type: "error",
      });
    }
  };

  return (
    <div className="rcontainer">
      <div className="header">
        <div>
          <div className="header-title">Candidates</div>
          <div className="header-subtitle">
            Manage and view the list of candidates, including their details,
            statuses, and actions.
          </div>
        </div>
        <div className="button-group">
          {/* <div>
            <button
              className="secondary-button"
              title="Export Recruiter's Data"
              onClick={() => {
                setIsExportModalOpen(true);
              }}
            >
              <img src={exportIcon} alt="exportIcon" />
              Export
            </button>
          </div> */}
          <div>
            <button
              className="secondary-button"
              title="Import bulk Recruiters"
              onClick={handleInvite}
            >
              {/* <img src={mailCheckIcon} alt="mailCheckIcon" /> */}
              Invite
            </button>
          </div>
          {/* <div>
            <button
              className="primary-button"
              onClick={() => {
                showCreateRecruiterModal();
                setEditModalVisible(false);
                setRecruiterFormData({
                  recruiterCode: "",
                  firstName: "",
                  lastName: "",
                  email: "",
                  phoneNumber: "",
                  state: "",
                  zone: "",
                  dateOfStarting: "",
                  dateOfLeaving: "",
                });
              }}
            >
              <img src={createRecruiterIcon} alt="createRecruiterIcon" />
              Create Recruiter
            </button>
          </div> */}
        </div>
      </div>

      <div className="stats-cards">
        <div className="stats-card">
          <div>
            <img src={twoUserIcon} alt="twoUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Total Candidates</div>
            <div className="value">{candidateCardCount.totalCandidates}</div>
          </div>
        </div>
        <div className="stats-card">
          <div>
            <img src={rightUserIcon} alt="rightUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Self Update</div>
            <div className="value">{candidateCardCount.selfUpdate}</div>
          </div>
        </div>
        <div className="stats-card">
          <div>
            <img src={rightUserIcon} alt="rightUserIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Updated by Recruiter</div>
            <div className="value">{candidateCardCount.updatedByRecruiter}</div>
          </div>
        </div>
        <div className="stats-card">
          <div>
            <img src={mailCheckIcon} alt="mailCheckIcon" />
          </div>
          <div className="stats-right-content">
            <div className="title">Invited Candidate</div>
            <div className="value">{candidateCardCount.invitedCandidates}</div>
          </div>
        </div>
      </div>

      <div className="filter-section">
        <div className="filter-bar">
          <div className="filter-group">
            <div>
              <Input
                placeholder="Search by Name, Email, PhoneNo or Recruiter Code"
                className="search-input"
                prefix={
                  <SearchOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "20px",
                    }}
                  />
                }
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div>
              <Select
                defaultValue="all"
                className="filter-select"
                onChange={handleStatusChange}
                options={[
                  { value: "all", label: "All" },
                  { value: "true", label: "Invited" },
                  { value: "false", label: "Not-Invited" },
                ]}
              />
            </div>
            <div>
              <Select
                defaultValue="all"
                className="filter-select"
                onChange={handleCandidateTypeChange}
                options={candidateType}
              />
            </div>
          </div>
          {/* <div>
            <button className="filter-button">
              <img src={filterIcon} alt="Filter Icon" /> <span>Filter</span>
            </button>
          </div> */}
        </div>
        <div className="table-container">
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
      </div>

      <Modal
        open={isExportModalOpen}
        width={760}
        maskClosable={false}
        title="Export Recruiter Data"
        onOk={() => {
          setIsExportModalOpen(false);
        }}
        onCancel={() => {
          setIsExportModalOpen(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button
              className="create-modal-cancel-button"
              onClick={() => {
                setIsExportModalOpen(false);
              }}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button
              className="create-modal-confirm-button"
              onClick={handleExportData}
              disabled={loading}
            >
              {loading ? "Exporting..." : "Export"}
            </Button>
          </>
        )}
      >
        <hr
          style={{ backgroundColor: "#ced7de", height: "1px", border: "none" }}
        />

        <div className="recruiter-create-raw" style={{ paddingTop: "15px" }}>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="startDate">
              Date of starting <span className="text-danger">*</span>
            </label>
            <DatePicker
              id="startDate"
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
              className="job-create-date-input"
              format={dateFormat}
              onChange={(date) => handleExportDateChange(date, "startDate")}
              value={recruiterExportData.startDate}
            />
          </div>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="endDate">Date of leaving</label>
            <DatePicker
              id="endDate"
              // disabledDate={(current) => {
              //   return current < recruiterFormData.startDate;
              // }}
              className="job-create-date-input"
              format={dateFormat}
              value={recruiterExportData.endDate}
              onChange={(date) => handleExportDateChange(date, "endDate")}
            />
          </div>
        </div>

        <div className="recruiter-create-raw" style={{ paddingTop: "15px" }}>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="state">State</label>
            <Select
              id="state"
              placeholder="Select a state"
              className="job-create-select-input"
              onChange={handleExportStateChange}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={states.map((state) => ({
                value: state.id,
                label: state.name,
                id: "state",
              }))}
              value={recruiterExportData.state || undefined}
            />
          </div>
          <div className="recruiter-create-input-div">
            {" "}
            <label htmlFor="zone">Zone</label>
            <Select
              id="zone"
              placeholder="Select a Zone"
              className="job-create-select-input"
              onChange={handleExportStateChange}
              options={zones.map((zone) => ({
                value: zone.value,
                label: zone.label,
                id: "zone",
              }))}
              value={recruiterExportData.zone || undefined}
            />
          </div>
        </div>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default CandidateListPage;
